import React, { useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { FormControl, Box, TextField, Typography } from "@material-ui/core"
import Button from "../../../../Button"
import sentSVG from "../../../../../images/sent.svg"
import clsx from "clsx"
import { useStyles } from "./style"
import { Link, navigate } from "gatsby"
import AuthContext from "../../../../../context/auth/authContext"

const PasswordCheck = () => {
  const classes = useStyles()
  const authContext = useContext(AuthContext)

  const {
    loading,
    user,
    forgotPassword,
  } = authContext

  useEffect(() => {
    user === null && navigate("/user/forgot-password")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const email = user !== null ? user : ""

  const initialValue = {
    email
  }

  const onSubmit = email => {
    forgotPassword(email)
  }

  return (
    <Box textAlign="center">
      <img src={sentSVG} alt="success" className={classes.successIcon} />
      <br />
      <br />
      <Typography align="center" className={classes.title}>
        Check your email
      </Typography>
      <Typography align="center" className={classes.subtitle}>
        A link has been sent to { email }. Click the link to reset your
        password
      </Typography>
      <br />
      <br />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ values, getFieldProps }) => (
          <Form noValidate autoComplete="off" className={classes.form}>
            <FormControl
              className={classes.formControl}
              style={{ display: "none" }}
            >
              <TextField
                name="email"
                id="email"
                value={email}
                variant="outlined"
                size="small"
              />
            </FormControl>

            <FormControl className={classes.emailResend}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                loading={loading}
              >
                Resend email
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
      <Box align="center">
        <Typography display="inline" className={classes.text}>
          Take me back to the
        </Typography>{" "}
        &nbsp;
        <Link to="/" style={{ textDecoration: "none" }}>
          <Typography
            display="inline"
            className={clsx(classes.actionText, classes.text)}
          >
            homepage
          </Typography>
        </Link>
      </Box>
    </Box>
  )
}

export default PasswordCheck
